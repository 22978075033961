import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4ef766e7 = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _f64237ac = () => interopDefault(import('../pages/admin/index/index.vue' /* webpackChunkName: "pages/admin/index/index" */))
const _5bea258b = () => interopDefault(import('../pages/admin/index/ads/index.vue' /* webpackChunkName: "pages/admin/index/ads/index" */))
const _84a49e70 = () => interopDefault(import('../pages/admin/index/bbm/index.vue' /* webpackChunkName: "pages/admin/index/bbm/index" */))
const _b0b418be = () => interopDefault(import('../pages/admin/index/notify.vue' /* webpackChunkName: "pages/admin/index/notify" */))
const _d6f738c2 = () => interopDefault(import('../pages/admin/index/pages/index.vue' /* webpackChunkName: "pages/admin/index/pages/index" */))
const _20bd4985 = () => interopDefault(import('../pages/admin/index/payments.vue' /* webpackChunkName: "pages/admin/index/payments" */))
const _154bd848 = () => interopDefault(import('../pages/admin/index/payments/index.vue' /* webpackChunkName: "pages/admin/index/payments/index" */))
const _7b27d63a = () => interopDefault(import('../pages/admin/index/payments/pages.vue' /* webpackChunkName: "pages/admin/index/payments/pages" */))
const _17c0d83e = () => interopDefault(import('../pages/admin/index/payments/ushop.vue' /* webpackChunkName: "pages/admin/index/payments/ushop" */))
const _2a03303c = () => interopDefault(import('../pages/admin/index/prompt.vue' /* webpackChunkName: "pages/admin/index/prompt" */))
const _df8ebb46 = () => interopDefault(import('../pages/admin/index/shippings.vue' /* webpackChunkName: "pages/admin/index/shippings" */))
const _5fadae64 = () => interopDefault(import('../pages/admin/index/shop.vue' /* webpackChunkName: "pages/admin/index/shop" */))
const _a00e5610 = () => interopDefault(import('../pages/admin/index/shop/products/index.vue' /* webpackChunkName: "pages/admin/index/shop/products/index" */))
const _76d614c6 = () => interopDefault(import('../pages/admin/index/shop/products/create.vue' /* webpackChunkName: "pages/admin/index/shop/products/create" */))
const _7f1268fa = () => interopDefault(import('../pages/admin/index/shop/products/_product.vue' /* webpackChunkName: "pages/admin/index/shop/products/_product" */))
const _c305e23c = () => interopDefault(import('../pages/admin/index/withdraw.vue' /* webpackChunkName: "pages/admin/index/withdraw" */))
const _532d1090 = () => interopDefault(import('../pages/admin/index/bbm/_uuid.vue' /* webpackChunkName: "pages/admin/index/bbm/_uuid" */))
const _342b9200 = () => interopDefault(import('../pages/boxboxmo/index.vue' /* webpackChunkName: "pages/boxboxmo/index" */))
const _6c904e8e = () => interopDefault(import('../pages/channels/index.vue' /* webpackChunkName: "pages/channels/index" */))
const _4ede3d96 = () => interopDefault(import('../pages/chat/index.vue' /* webpackChunkName: "pages/chat/index" */))
const _ed0cf14c = () => interopDefault(import('../pages/home.vue' /* webpackChunkName: "pages/home" */))
const _3e94bb46 = () => interopDefault(import('../pages/home/index.vue' /* webpackChunkName: "pages/home/index" */))
const _691e562e = () => interopDefault(import('../pages/home/assistant.vue' /* webpackChunkName: "pages/home/assistant" */))
const _59da48ac = () => interopDefault(import('../pages/home/assistant/index.vue' /* webpackChunkName: "pages/home/assistant/index" */))
const _09231792 = () => interopDefault(import('../pages/home/assistant/create.vue' /* webpackChunkName: "pages/home/assistant/create" */))
const _21401e1b = () => interopDefault(import('../pages/home/assistant/default.vue' /* webpackChunkName: "pages/home/assistant/default" */))
const _026a332d = () => interopDefault(import('../pages/home/assistant/posts.vue' /* webpackChunkName: "pages/home/assistant/posts" */))
const _4347a60a = () => interopDefault(import('../pages/home/assistant/_media.vue' /* webpackChunkName: "pages/home/assistant/_media" */))
const _39fff11d = () => interopDefault(import('../pages/home/me.vue' /* webpackChunkName: "pages/home/me" */))
const _7c058240 = () => interopDefault(import('../pages/home/password.vue' /* webpackChunkName: "pages/home/password" */))
const _24b553c6 = () => interopDefault(import('../pages/home/signup.vue' /* webpackChunkName: "pages/home/signup" */))
const _2e420d66 = () => interopDefault(import('../pages/home/channels/create.vue' /* webpackChunkName: "pages/home/channels/create" */))
const _74a23fae = () => interopDefault(import('../pages/home/channels/_channel.vue' /* webpackChunkName: "pages/home/channels/_channel" */))
const _9acf88ee = () => interopDefault(import('../pages/home/posts/_id.vue' /* webpackChunkName: "pages/home/posts/_id" */))
const _2b129805 = () => interopDefault(import('../pages/home/_id.vue' /* webpackChunkName: "pages/home/_id" */))
const _35e77270 = () => interopDefault(import('../pages/home/_id/index.vue' /* webpackChunkName: "pages/home/_id/index" */))
const _379e4614 = () => interopDefault(import('../pages/home/_id/create.vue' /* webpackChunkName: "pages/home/_id/create" */))
const _47fa56a8 = () => interopDefault(import('../pages/home/_id/instagram.vue' /* webpackChunkName: "pages/home/_id/instagram" */))
const _216b40f4 = () => interopDefault(import('../pages/home/_id/instagram_poster.vue' /* webpackChunkName: "pages/home/_id/instagram_poster" */))
const _19f04db7 = () => interopDefault(import('../pages/home/_id/instagramwithfacebook/index.vue' /* webpackChunkName: "pages/home/_id/instagramwithfacebook/index" */))
const _62447a27 = () => interopDefault(import('../pages/home/_id/payments.vue' /* webpackChunkName: "pages/home/_id/payments" */))
const _04521d8d = () => interopDefault(import('../pages/home/_id/pending.vue' /* webpackChunkName: "pages/home/_id/pending" */))
const _e4c79d6e = () => interopDefault(import('../pages/home/_id/posts.vue' /* webpackChunkName: "pages/home/_id/posts" */))
const _33e6b9d0 = () => interopDefault(import('../pages/home/_id/preference/index.vue' /* webpackChunkName: "pages/home/_id/preference/index" */))
const _377ae379 = () => interopDefault(import('../pages/home/_id/private.vue' /* webpackChunkName: "pages/home/_id/private" */))
const _79950a9c = () => interopDefault(import('../pages/home/_id/schedules.vue' /* webpackChunkName: "pages/home/_id/schedules" */))
const _c80d1c48 = () => interopDefault(import('../pages/home/_id/styles.vue' /* webpackChunkName: "pages/home/_id/styles" */))
const _361aee9f = () => interopDefault(import('../pages/home/_id/threads.vue' /* webpackChunkName: "pages/home/_id/threads" */))
const _6f63f574 = () => interopDefault(import('../pages/home/_id/preference/autopublish.vue' /* webpackChunkName: "pages/home/_id/preference/autopublish" */))
const _7350a3cb = () => interopDefault(import('../pages/home/_id/preference/binding.vue' /* webpackChunkName: "pages/home/_id/preference/binding" */))
const _d22c2b84 = () => interopDefault(import('../pages/home/_id/preference/scheduling.vue' /* webpackChunkName: "pages/home/_id/preference/scheduling" */))
const _fa9cdb5a = () => interopDefault(import('../pages/home/_id/instagramwithfacebook/_page.vue' /* webpackChunkName: "pages/home/_id/instagramwithfacebook/_page" */))
const _3b13d496 = () => interopDefault(import('../pages/me/index.vue' /* webpackChunkName: "pages/me/index" */))
const _4e829fe9 = () => interopDefault(import('../pages/myshops/index.vue' /* webpackChunkName: "pages/myshops/index" */))
const _4f50d859 = () => interopDefault(import('../pages/permissions.vue' /* webpackChunkName: "pages/permissions" */))
const _d035e846 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _9100fae6 = () => interopDefault(import('../pages/receipt.vue' /* webpackChunkName: "pages/receipt" */))
const _601e26b8 = () => interopDefault(import('../pages/support.vue' /* webpackChunkName: "pages/support" */))
const _08e886fd = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _69c5d4bf = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _3ad8fee8 = () => interopDefault(import('../pages/boxboxmo/chats/index.vue' /* webpackChunkName: "pages/boxboxmo/chats/index" */))
const _fb815e62 = () => interopDefault(import('../pages/boxboxmo/lgbt.vue' /* webpackChunkName: "pages/boxboxmo/lgbt" */))
const _907b9146 = () => interopDefault(import('../pages/boxboxmo/me/index.vue' /* webpackChunkName: "pages/boxboxmo/me/index" */))
const _283e0800 = () => interopDefault(import('../pages/boxboxmo/put/index.vue' /* webpackChunkName: "pages/boxboxmo/put/index" */))
const _d9097a46 = () => interopDefault(import('../pages/boxboxmo/recharges/index.vue' /* webpackChunkName: "pages/boxboxmo/recharges/index" */))
const _0f911473 = () => interopDefault(import('../pages/boxboxmo/records/index.vue' /* webpackChunkName: "pages/boxboxmo/records/index" */))
const _59b77c19 = () => interopDefault(import('../pages/docs/instagram.vue' /* webpackChunkName: "pages/docs/instagram" */))
const _f6e7b6a2 = () => interopDefault(import('../pages/docs/privacy.vue' /* webpackChunkName: "pages/docs/privacy" */))
const _4b996347 = () => interopDefault(import('../pages/docs/services.vue' /* webpackChunkName: "pages/docs/services" */))
const _494a9c96 = () => interopDefault(import('../pages/docs/tg_token.vue' /* webpackChunkName: "pages/docs/tg_token" */))
const _c1d68760 = () => interopDefault(import('../pages/docs/threads.vue' /* webpackChunkName: "pages/docs/threads" */))
const _5963fe47 = () => interopDefault(import('../pages/docs/tutorial.vue' /* webpackChunkName: "pages/docs/tutorial" */))
const _289e7ae2 = () => interopDefault(import('../pages/me/shop.vue' /* webpackChunkName: "pages/me/shop" */))
const _3967cfe5 = () => interopDefault(import('../pages/me/shop/index.vue' /* webpackChunkName: "pages/me/shop/index" */))
const _6f83ab27 = () => interopDefault(import('../pages/me/shop/dashboard.vue' /* webpackChunkName: "pages/me/shop/dashboard" */))
const _adda1c36 = () => interopDefault(import('../pages/me/shop/orders/index.vue' /* webpackChunkName: "pages/me/shop/orders/index" */))
const _175a9864 = () => interopDefault(import('../pages/me/shop/products/index.vue' /* webpackChunkName: "pages/me/shop/products/index" */))
const _24d70c4f = () => interopDefault(import('../pages/boxboxmo/me/edit.vue' /* webpackChunkName: "pages/boxboxmo/me/edit" */))
const _51c0a291 = () => interopDefault(import('../pages/boxboxmo/me/invite/index.vue' /* webpackChunkName: "pages/boxboxmo/me/invite/index" */))
const _fae7ea62 = () => interopDefault(import('../pages/boxboxmo/me/withdraw.vue' /* webpackChunkName: "pages/boxboxmo/me/withdraw" */))
const _0c16b132 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6dc76971 = () => interopDefault(import('../pages/boxboxmo/chats/_chat.vue' /* webpackChunkName: "pages/boxboxmo/chats/_chat" */))
const _34d1480e = () => interopDefault(import('../pages/boxboxmo/put/_no.vue' /* webpackChunkName: "pages/boxboxmo/put/_no" */))
const _44e9962b = () => interopDefault(import('../pages/boxboxmo/recharges/_no.vue' /* webpackChunkName: "pages/boxboxmo/recharges/_no" */))
const _0023501a = () => interopDefault(import('../pages/boxboxmo/take/_no.vue' /* webpackChunkName: "pages/boxboxmo/take/_no" */))
const _2fe13b8a = () => interopDefault(import('../pages/channels/_channel/index.vue' /* webpackChunkName: "pages/channels/_channel/index" */))
const _62490572 = () => interopDefault(import('../pages/feed/_id/index.vue' /* webpackChunkName: "pages/feed/_id/index" */))
const _4367a071 = () => interopDefault(import('../pages/me/_user/index.vue' /* webpackChunkName: "pages/me/_user/index" */))
const _6f74d16c = () => interopDefault(import('../pages/myshops/_shop.vue' /* webpackChunkName: "pages/myshops/_shop" */))
const _63c196ef = () => interopDefault(import('../pages/myshops/_shop/index.vue' /* webpackChunkName: "pages/myshops/_shop/index" */))
const _5e082460 = () => interopDefault(import('../pages/myshops/_shop/ads/index.vue' /* webpackChunkName: "pages/myshops/_shop/ads/index" */))
const _476c307b = () => interopDefault(import('../pages/myshops/_shop/assistant.vue' /* webpackChunkName: "pages/myshops/_shop/assistant" */))
const _64a58962 = () => interopDefault(import('../pages/myshops/_shop/instagram.vue' /* webpackChunkName: "pages/myshops/_shop/instagram" */))
const _1d71e8ca = () => interopDefault(import('../pages/myshops/_shop/orders/index.vue' /* webpackChunkName: "pages/myshops/_shop/orders/index" */))
const _2c129952 = () => interopDefault(import('../pages/myshops/_shop/products.vue' /* webpackChunkName: "pages/myshops/_shop/products" */))
const _22ef1c9a = () => interopDefault(import('../pages/myshops/_shop/products/index.vue' /* webpackChunkName: "pages/myshops/_shop/products/index" */))
const _26c10207 = () => interopDefault(import('../pages/myshops/_shop/products/categories/index.vue' /* webpackChunkName: "pages/myshops/_shop/products/categories/index" */))
const _dfc8e596 = () => interopDefault(import('../pages/myshops/_shop/products/poster.vue' /* webpackChunkName: "pages/myshops/_shop/products/poster" */))
const _74aa5fab = () => interopDefault(import('../pages/myshops/_shop/products/variations/index.vue' /* webpackChunkName: "pages/myshops/_shop/products/variations/index" */))
const _7e2733d0 = () => interopDefault(import('../pages/myshops/_shop/products/_product.vue' /* webpackChunkName: "pages/myshops/_shop/products/_product" */))
const _0615ce2a = () => interopDefault(import('../pages/myshops/_shop/promocodes.vue' /* webpackChunkName: "pages/myshops/_shop/promocodes" */))
const _614f802d = () => interopDefault(import('../pages/myshops/_shop/setting.vue' /* webpackChunkName: "pages/myshops/_shop/setting" */))
const _67f470f0 = () => interopDefault(import('../pages/myshops/_shop/setting/index.vue' /* webpackChunkName: "pages/myshops/_shop/setting/index" */))
const _46b0330e = () => interopDefault(import('../pages/myshops/_shop/setting/groupbuy.vue' /* webpackChunkName: "pages/myshops/_shop/setting/groupbuy" */))
const _68f12cd0 = () => interopDefault(import('../pages/myshops/_shop/setting/instagram.vue' /* webpackChunkName: "pages/myshops/_shop/setting/instagram" */))
const _0603426a = () => interopDefault(import('../pages/myshops/_shop/setting/off_day.vue' /* webpackChunkName: "pages/myshops/_shop/setting/off_day" */))
const _fe819d28 = () => interopDefault(import('../pages/myshops/_shop/setting/order.vue' /* webpackChunkName: "pages/myshops/_shop/setting/order" */))
const _90c44122 = () => interopDefault(import('../pages/myshops/_shop/setting/order_cut.vue' /* webpackChunkName: "pages/myshops/_shop/setting/order_cut" */))
const _2e59e500 = () => interopDefault(import('../pages/myshops/_shop/setting/shipping.vue' /* webpackChunkName: "pages/myshops/_shop/setting/shipping" */))
const _27255912 = () => interopDefault(import('../pages/myshops/_shop/setting/sms.vue' /* webpackChunkName: "pages/myshops/_shop/setting/sms" */))
const _03b0de4f = () => interopDefault(import('../pages/myshops/_shop/setting/style.vue' /* webpackChunkName: "pages/myshops/_shop/setting/style" */))
const _217e7e83 = () => interopDefault(import('../pages/myshops/_shop/summary.vue' /* webpackChunkName: "pages/myshops/_shop/summary" */))
const _c4c7cb54 = () => interopDefault(import('../pages/myshops/_shop/orders/_order.vue' /* webpackChunkName: "pages/myshops/_shop/orders/_order" */))
const _380bb99f = () => interopDefault(import('../pages/passcode/_username.vue' /* webpackChunkName: "pages/passcode/_username" */))
const _61c22311 = () => interopDefault(import('../pages/s/_shop/index.vue' /* webpackChunkName: "pages/s/_shop/index" */))
const _1901893b = () => interopDefault(import('../pages/feed/_id/payment.vue' /* webpackChunkName: "pages/feed/_id/payment" */))
const _7d82db5c = () => interopDefault(import('../pages/feed/_id/vip.vue' /* webpackChunkName: "pages/feed/_id/vip" */))
const _47447ea8 = () => interopDefault(import('../pages/s/_shop/me/index.vue' /* webpackChunkName: "pages/s/_shop/me/index" */))
const _23b8f539 = () => interopDefault(import('../pages/s/_shop/orders/index.vue' /* webpackChunkName: "pages/s/_shop/orders/index" */))
const _5726f3af = () => interopDefault(import('../pages/s/_shop/orders/checkout.vue' /* webpackChunkName: "pages/s/_shop/orders/checkout" */))
const _a3287938 = () => interopDefault(import('../pages/channels/_channel/topics/_topic.vue' /* webpackChunkName: "pages/channels/_channel/topics/_topic" */))
const _8d313710 = () => interopDefault(import('../pages/s/_shop/orders/_order.vue' /* webpackChunkName: "pages/s/_shop/orders/_order" */))
const _13e1cf3a = () => interopDefault(import('../pages/s/_shop/products/_product.vue' /* webpackChunkName: "pages/s/_shop/products/_product" */))
const _4e31ff8e = () => interopDefault(import('../pages/me/_user/_uuid.vue' /* webpackChunkName: "pages/me/_user/_uuid" */))
const _5db6d79e = () => interopDefault(import('../pages/s/_shop/_.vue' /* webpackChunkName: "pages/s/_shop/_" */))
const _e464925c = () => interopDefault(import('../pages/_id/index.vue' /* webpackChunkName: "pages/_id/index" */))
const _6c1a400c = () => interopDefault(import('../pages/_id/destroy.vue' /* webpackChunkName: "pages/_id/destroy" */))
const _365da153 = () => interopDefault(import('../pages/_id/posts.vue' /* webpackChunkName: "pages/_id/posts" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _4ef766e7,
    children: [{
      path: "",
      component: _f64237ac,
      name: "admin-index___zh-mo"
    }, {
      path: "ads",
      component: _5bea258b,
      name: "admin-index-ads___zh-mo"
    }, {
      path: "bbm",
      component: _84a49e70,
      name: "admin-index-bbm___zh-mo"
    }, {
      path: "notify",
      component: _b0b418be,
      name: "admin-index-notify___zh-mo"
    }, {
      path: "pages",
      component: _d6f738c2,
      name: "admin-index-pages___zh-mo"
    }, {
      path: "payments",
      component: _20bd4985,
      children: [{
        path: "",
        component: _154bd848,
        name: "admin-index-payments___zh-mo"
      }, {
        path: "pages",
        component: _7b27d63a,
        name: "admin-index-payments-pages___zh-mo"
      }, {
        path: "ushop",
        component: _17c0d83e,
        name: "admin-index-payments-ushop___zh-mo"
      }]
    }, {
      path: "prompt",
      component: _2a03303c,
      name: "admin-index-prompt___zh-mo"
    }, {
      path: "shippings",
      component: _df8ebb46,
      name: "admin-index-shippings___zh-mo"
    }, {
      path: "shop",
      component: _5fadae64,
      name: "admin-index-shop___zh-mo",
      children: [{
        path: "products",
        component: _a00e5610,
        name: "admin-index-shop-products___zh-mo"
      }, {
        path: "products/create",
        component: _76d614c6,
        name: "admin-index-shop-products-create___zh-mo"
      }, {
        path: "products/:product",
        component: _7f1268fa,
        name: "admin-index-shop-products-product___zh-mo"
      }]
    }, {
      path: "withdraw",
      component: _c305e23c,
      name: "admin-index-withdraw___zh-mo"
    }, {
      path: "bbm/:uuid?",
      component: _532d1090,
      name: "admin-index-bbm-uuid___zh-mo"
    }]
  }, {
    path: "/boxboxmo",
    component: _342b9200,
    name: "boxboxmo___zh-mo"
  }, {
    path: "/channels",
    component: _6c904e8e,
    name: "channels___zh-mo"
  }, {
    path: "/chat",
    component: _4ede3d96,
    name: "chat___zh-mo"
  }, {
    path: "/home",
    component: _ed0cf14c,
    children: [{
      path: "",
      component: _3e94bb46,
      name: "home___zh-mo"
    }, {
      path: "assistant",
      component: _691e562e,
      children: [{
        path: "",
        component: _59da48ac,
        name: "home-assistant___zh-mo"
      }, {
        path: "create",
        component: _09231792,
        name: "home-assistant-create___zh-mo"
      }, {
        path: "default",
        component: _21401e1b,
        name: "home-assistant-default___zh-mo"
      }, {
        path: "posts",
        component: _026a332d,
        name: "home-assistant-posts___zh-mo"
      }, {
        path: ":media",
        component: _4347a60a,
        name: "home-assistant-media___zh-mo"
      }]
    }, {
      path: "me",
      component: _39fff11d,
      name: "home-me___zh-mo"
    }, {
      path: "password",
      component: _7c058240,
      name: "home-password___zh-mo"
    }, {
      path: "signup",
      component: _24b553c6,
      name: "home-signup___zh-mo"
    }, {
      path: "channels/create",
      component: _2e420d66,
      name: "home-channels-create___zh-mo"
    }, {
      path: "channels/:channel?",
      component: _74a23fae,
      name: "home-channels-channel___zh-mo"
    }, {
      path: "posts/:id?",
      component: _9acf88ee,
      name: "home-posts-id___zh-mo"
    }, {
      path: ":id",
      component: _2b129805,
      children: [{
        path: "",
        component: _35e77270,
        name: "home-id___zh-mo"
      }, {
        path: "create",
        component: _379e4614,
        name: "home-id-create___zh-mo"
      }, {
        path: "instagram",
        component: _47fa56a8,
        name: "home-id-instagram___zh-mo"
      }, {
        path: "instagram_poster",
        component: _216b40f4,
        name: "home-id-instagram_poster___zh-mo"
      }, {
        path: "instagramwithfacebook",
        component: _19f04db7,
        name: "home-id-instagramwithfacebook___zh-mo"
      }, {
        path: "payments",
        component: _62447a27,
        name: "home-id-payments___zh-mo"
      }, {
        path: "pending",
        component: _04521d8d,
        name: "home-id-pending___zh-mo"
      }, {
        path: "posts",
        component: _e4c79d6e,
        name: "home-id-posts___zh-mo"
      }, {
        path: "preference",
        component: _33e6b9d0,
        name: "home-id-preference___zh-mo"
      }, {
        path: "private",
        component: _377ae379,
        name: "home-id-private___zh-mo"
      }, {
        path: "schedules",
        component: _79950a9c,
        name: "home-id-schedules___zh-mo"
      }, {
        path: "styles",
        component: _c80d1c48,
        name: "home-id-styles___zh-mo"
      }, {
        path: "threads",
        component: _361aee9f,
        name: "home-id-threads___zh-mo"
      }, {
        path: "preference/autopublish",
        component: _6f63f574,
        name: "home-id-preference-autopublish___zh-mo"
      }, {
        path: "preference/binding",
        component: _7350a3cb,
        name: "home-id-preference-binding___zh-mo"
      }, {
        path: "preference/scheduling",
        component: _d22c2b84,
        name: "home-id-preference-scheduling___zh-mo"
      }, {
        path: "instagramwithfacebook/:page",
        component: _fa9cdb5a,
        name: "home-id-instagramwithfacebook-page___zh-mo"
      }]
    }]
  }, {
    path: "/me",
    component: _3b13d496,
    name: "me___zh-mo"
  }, {
    path: "/myshops",
    component: _4e829fe9,
    name: "myshops___zh-mo"
  }, {
    path: "/permissions",
    component: _4f50d859,
    name: "permissions___zh-mo"
  }, {
    path: "/privacy",
    component: _d035e846,
    name: "privacy___zh-mo"
  }, {
    path: "/receipt",
    component: _9100fae6,
    name: "receipt___zh-mo"
  }, {
    path: "/support",
    component: _601e26b8,
    name: "support___zh-mo"
  }, {
    path: "/auth/login",
    component: _08e886fd,
    name: "auth-login___zh-mo"
  }, {
    path: "/auth/register",
    component: _69c5d4bf,
    name: "auth-register___zh-mo"
  }, {
    path: "/boxboxmo/chats",
    component: _3ad8fee8,
    name: "boxboxmo-chats___zh-mo"
  }, {
    path: "/boxboxmo/lgbt",
    component: _fb815e62,
    name: "boxboxmo-lgbt___zh-mo"
  }, {
    path: "/boxboxmo/me",
    component: _907b9146,
    name: "boxboxmo-me___zh-mo"
  }, {
    path: "/boxboxmo/put",
    component: _283e0800,
    name: "boxboxmo-put___zh-mo"
  }, {
    path: "/boxboxmo/recharges",
    component: _d9097a46,
    name: "boxboxmo-recharges___zh-mo"
  }, {
    path: "/boxboxmo/records",
    component: _0f911473,
    name: "boxboxmo-records___zh-mo"
  }, {
    path: "/docs/instagram",
    component: _59b77c19,
    name: "docs-instagram___zh-mo"
  }, {
    path: "/docs/privacy",
    component: _f6e7b6a2,
    name: "docs-privacy___zh-mo"
  }, {
    path: "/docs/services",
    component: _4b996347,
    name: "docs-services___zh-mo"
  }, {
    path: "/docs/tg_token",
    component: _494a9c96,
    name: "docs-tg_token___zh-mo"
  }, {
    path: "/docs/threads",
    component: _c1d68760,
    name: "docs-threads___zh-mo"
  }, {
    path: "/docs/tutorial",
    component: _5963fe47,
    name: "docs-tutorial___zh-mo"
  }, {
    path: "/me/shop",
    component: _289e7ae2,
    children: [{
      path: "",
      component: _3967cfe5,
      name: "me-shop___zh-mo"
    }, {
      path: "dashboard",
      component: _6f83ab27,
      name: "me-shop-dashboard___zh-mo"
    }, {
      path: "orders",
      component: _adda1c36,
      name: "me-shop-orders___zh-mo"
    }, {
      path: "products",
      component: _175a9864,
      name: "me-shop-products___zh-mo"
    }]
  }, {
    path: "/boxboxmo/me/edit",
    component: _24d70c4f,
    name: "boxboxmo-me-edit___zh-mo"
  }, {
    path: "/boxboxmo/me/invite",
    component: _51c0a291,
    name: "boxboxmo-me-invite___zh-mo"
  }, {
    path: "/boxboxmo/me/withdraw",
    component: _fae7ea62,
    name: "boxboxmo-me-withdraw___zh-mo"
  }, {
    path: "/",
    component: _0c16b132,
    name: "index___zh-mo"
  }, {
    path: "/boxboxmo/chats/:chat",
    component: _6dc76971,
    name: "boxboxmo-chats-chat___zh-mo"
  }, {
    path: "/boxboxmo/put/:no",
    component: _34d1480e,
    name: "boxboxmo-put-no___zh-mo"
  }, {
    path: "/boxboxmo/recharges/:no",
    component: _44e9962b,
    name: "boxboxmo-recharges-no___zh-mo"
  }, {
    path: "/boxboxmo/take/:no?",
    component: _0023501a,
    name: "boxboxmo-take-no___zh-mo"
  }, {
    path: "/channels/:channel",
    component: _2fe13b8a,
    name: "channels-channel___zh-mo"
  }, {
    path: "/feed/:id",
    component: _62490572,
    name: "feed-id___zh-mo"
  }, {
    path: "/me/:user",
    component: _4367a071,
    name: "me-user___zh-mo"
  }, {
    path: "/myshops/:shop",
    component: _6f74d16c,
    children: [{
      path: "",
      component: _63c196ef,
      name: "myshops-shop___zh-mo"
    }, {
      path: "ads",
      component: _5e082460,
      name: "myshops-shop-ads___zh-mo"
    }, {
      path: "assistant",
      component: _476c307b,
      name: "myshops-shop-assistant___zh-mo"
    }, {
      path: "instagram",
      component: _64a58962,
      name: "myshops-shop-instagram___zh-mo"
    }, {
      path: "orders",
      component: _1d71e8ca,
      name: "myshops-shop-orders___zh-mo"
    }, {
      path: "products",
      component: _2c129952,
      children: [{
        path: "",
        component: _22ef1c9a,
        name: "myshops-shop-products___zh-mo"
      }, {
        path: "categories",
        component: _26c10207,
        name: "myshops-shop-products-categories___zh-mo"
      }, {
        path: "poster",
        component: _dfc8e596,
        name: "myshops-shop-products-poster___zh-mo"
      }, {
        path: "variations",
        component: _74aa5fab,
        name: "myshops-shop-products-variations___zh-mo"
      }, {
        path: ":product",
        component: _7e2733d0,
        name: "myshops-shop-products-product___zh-mo"
      }]
    }, {
      path: "promocodes",
      component: _0615ce2a,
      name: "myshops-shop-promocodes___zh-mo"
    }, {
      path: "setting",
      component: _614f802d,
      children: [{
        path: "",
        component: _67f470f0,
        name: "myshops-shop-setting___zh-mo"
      }, {
        path: "groupbuy",
        component: _46b0330e,
        name: "myshops-shop-setting-groupbuy___zh-mo"
      }, {
        path: "instagram",
        component: _68f12cd0,
        name: "myshops-shop-setting-instagram___zh-mo"
      }, {
        path: "off_day",
        component: _0603426a,
        name: "myshops-shop-setting-off_day___zh-mo"
      }, {
        path: "order",
        component: _fe819d28,
        name: "myshops-shop-setting-order___zh-mo"
      }, {
        path: "order_cut",
        component: _90c44122,
        name: "myshops-shop-setting-order_cut___zh-mo"
      }, {
        path: "shipping",
        component: _2e59e500,
        name: "myshops-shop-setting-shipping___zh-mo"
      }, {
        path: "sms",
        component: _27255912,
        name: "myshops-shop-setting-sms___zh-mo"
      }, {
        path: "style",
        component: _03b0de4f,
        name: "myshops-shop-setting-style___zh-mo"
      }]
    }, {
      path: "summary",
      component: _217e7e83,
      name: "myshops-shop-summary___zh-mo"
    }, {
      path: "orders/:order",
      component: _c4c7cb54,
      name: "myshops-shop-orders-order___zh-mo"
    }]
  }, {
    path: "/passcode/:username?",
    component: _380bb99f,
    name: "passcode-username___zh-mo"
  }, {
    path: "/s/:shop",
    component: _61c22311,
    name: "s-shop___zh-mo"
  }, {
    path: "/feed/:id?/payment",
    component: _1901893b,
    name: "feed-id-payment___zh-mo"
  }, {
    path: "/feed/:id?/vip",
    component: _7d82db5c,
    name: "feed-id-vip___zh-mo"
  }, {
    path: "/s/:shop?/me",
    component: _47447ea8,
    name: "s-shop-me___zh-mo"
  }, {
    path: "/s/:shop?/orders",
    component: _23b8f539,
    name: "s-shop-orders___zh-mo"
  }, {
    path: "/s/:shop?/orders/checkout",
    component: _5726f3af,
    name: "s-shop-orders-checkout___zh-mo"
  }, {
    path: "/channels/:channel/topics/:topic?",
    component: _a3287938,
    name: "channels-channel-topics-topic___zh-mo"
  }, {
    path: "/s/:shop?/orders/:order",
    component: _8d313710,
    name: "s-shop-orders-order___zh-mo"
  }, {
    path: "/s/:shop?/products/:product?",
    component: _13e1cf3a,
    name: "s-shop-products-product___zh-mo"
  }, {
    path: "/me/:user/:uuid",
    component: _4e31ff8e,
    name: "me-user-uuid___zh-mo"
  }, {
    path: "/s/:shop?/*",
    component: _5db6d79e,
    name: "s-shop-all___zh-mo"
  }, {
    path: "/:id",
    component: _e464925c,
    name: "id___zh-mo"
  }, {
    path: "/:id/destroy",
    component: _6c1a400c,
    name: "id-destroy___zh-mo"
  }, {
    path: "/:id/posts",
    component: _365da153,
    name: "id-posts___zh-mo"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
