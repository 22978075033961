<template>
  <div class="md:py-8 py-2 text-center text-xs mb-safe">
    <p class="mb-4 leading-5">
      <span>©2021 CLOVER COMPUTER LTD. 版權所有。</span>
    </p>
    <p class="mb-4">
      <nuxt-link class="text-primary" :to="localePath({ name: 'docs-privacy' })"
        >隱私政策</nuxt-link
      >
      &
      <nuxt-link class="text-primary" :to="localePath({ name: 'docs-services' })"
        >服務條款</nuxt-link
      >
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>

<style lang="scss" scoped></style>
